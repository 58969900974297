import * as React from "react";
import { Link } from "react-router-dom";
import {
  Edit,
  required,
  TextInput,
  maxLength,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  Datagrid,
  EditButton,
  ReferenceManyField,
  TextField,
  TabbedForm,
  Pagination,
  FormTab,
  Button,
  ImageField,
  ImageInput,
  ReferenceField,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
  tab: {
    maxWidth: "40em",
    display: "block",
  },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

const useStyles = makeStyles(styles);

const validateTextArea = maxLength(1000);

const CourseTitle = ({ record }) => {
  return record ? (
    <span>
      Course &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

const CreateGroupButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/groups/create",
      // Here we specify the initial record for the create view
      state: { record: { courseId: record.id } },
    }}
    label="Add group"
  >
    <AddIcon />
  </Button>
);

const CreateModuleButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/modules/create",
      // Here we specify the initial record for the create view
      state: { record: { courseId: record.id } },
    }}
    label="Add module"
  >
    <AddIcon />
  </Button>
);

// const CreateReviewButton = ({record}) => (
//   <Button
//     component={Link}
//     to={{
//       pathname: '/reviews/create',
//       // Here we specify the initial record for the create view
//       state: {record: {courseId: record.id}},
//     }}
//     label='Add review'>
//     <AddIcon />
//   </Button>
// );

const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const CourseEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit title={<CourseTitle />} {...props}>
      <TabbedForm>
        <FormTab label="Details" contentClassName={classes.tab}>
          <TextInput
            source="name"
            validate={required()}
            formClassName={classes.inline}
          />
          <NumberInput
            source="price"
            validate={required()}
            formClassName={classes.inline}
          />
          <ReferenceInput
            source="instructorId"
            reference="instructors"
            validate={required()}
            formClassName={classes.inline}
          >
            <SelectInput
              optionText="name"
              optionValue="id"
              formClassName={classes.inline}
            />
          </ReferenceInput>
          <TextInput source="duration" formClassName={classes.inline} />
          <TextInput
            source="description"
            multiline
            resettable
            fullWidth
            validate={validateTextArea}
            formClassName={classes.margin}
          />
          <TextInput
            source="whatYouWillLearn"
            multiline
            resettable
            fullWidth
            validate={validateTextArea}
            formClassName={classes.margin}
          />
          <TextInput
            source="whoThisCourseIsFor"
            multiline
            resettable
            fullWidth
            validate={validateTextArea}
            formClassName={classes.margin}
          />
          <ImageInput source="imgUrl" label="Image" accept="image/*">
            {/* <ImageField source="imgUrl" title="title" /> */}
            <PreviewImage source="imgUrl" />
          </ImageInput>
          <BooleanInput
            source="isFeatured"
            label="Featured"
            formClassName={classes.inline}
          />
          <BooleanInput
            source="status"
            label="Available"
            formClassName={classes.inline}
          />
        </FormTab>

        <FormTab label="Modules" path="modules">
          <ReferenceManyField
            reference="modules"
            target="courseId"
            addLabel={false}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid>
              <TextField source="title" />
              <TextField source="duration" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <CreateModuleButton />
        </FormTab>

        <FormTab label="Groups" path="groups">
          <ReferenceManyField
            reference="groups"
            target="courseId"
            addLabel={false}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid>
              <TextField source="title" />
              <TextField source="zoomLink" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <CreateGroupButton />
        </FormTab>

        {/* <FormTab label='Reviews' path='reviews'>
          <ReferenceManyField
            reference='reviews'
            target='courseId'
            addLabel={false}
            pagination={<Pagination />}
            fullWidth>
            <Datagrid>
              <ReferenceField source='studentId' reference='students'>
                <TextField source='name' />
              </ReferenceField>
              <TextField source='rating' />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <CreateReviewButton />
        </FormTab> */}
      </TabbedForm>
    </Edit>
  );
};

export default CourseEdit;
