import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { cloneElement } from "react";
import {
  Datagrid,
  EditButton,
  ExportButton,
  List,
  ReferenceField,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from "react-admin";
import { getDate } from "../utils";

const useStyles = makeStyles({
  root: {
    marginTop: "1em",
  },
  media: {
    height: 140,
    backgroundSize: "contain",
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
});

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* <ListButton basePath={basePath} /> */}
      {/* <CreateButton basePath={basePath} /> */}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const StatusField = ({ record, source }) => {
  record = {
    [source]: record.status == 0 ? "Unconfirmed" : "Confirmed",
  };
  return <TextField record={record} source={source} />;
};

const DateField = ({ record, source }) => {
  const newDate = getDate(record.createdAt.toString());
  record = {
    [source]: newDate,
  };

  return <TextField record={record} source={source} />;
};

const PromoDiscountField = ({ record, source }) => {
  record = {
    [source]: record.promoDiscount
      ? `${record.promoDiscount.toFixed(2)} USD`
      : null,
  };
  return <TextField record={record} source={source} />;
};

export const OrderList = (props) => (
  <List {...props} actions={<ListActions />} bulkActionButtons={false}>
    <Datagrid>
    <ReferenceField source="courseId" reference="courses">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="groupId" reference="groups">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="studentId" reference="students">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="price" />
      <StatusField source="status" />
      <ReferenceField source="promoId" reference="promos">
        <TextField source="code" />
      </ReferenceField>
      <PromoDiscountField source="promoDiscount" />
      <DateField source="createdAt" />
      <EditButton />
    </Datagrid>
  </List>
);

export default OrderList;
