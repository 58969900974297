import * as React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  maxLength,
  email,
  BooleanInput,
  ImageField,
  ImageInput,
  PasswordInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
  password: {display: 'inline-block'},
  confirmPassword: {display: 'inline-block', marginLeft: 32},
};

const useStyles = makeStyles(styles);

const PreviewImage = ({record, source}) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const validateEmail = [required(), email()];

export const validatePasswords = ({password, confirmPassword}) => {
  const errors = {};
  if (password && confirmPassword && password !== confirmPassword) {
    errors.confirmPassword = [
      'The password confirmation is not the same as the password.',
    ];
  }
  return errors;
};
const redirect = (basePath, id, data) => `/students`;

const StudentCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm validate={validatePasswords} redirect={redirect}>
        <TextInput
          source='name'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='email'
          validate={validateEmail}
          formClassName={classes.inline}
        />
        <TextInput
          source='countryCode'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='phone'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='country'
          validate={required()}
          formClassName={classes.inline}
        />
        <PasswordInput
          source='password'
          validate={required()}
          formClassName={classes.inline}
        />
        <PasswordInput
          source='confirmPassword'
          validate={required()}
          formClassName={classes.inline}
        />
        <BooleanInput source='phoneVerified' formClassName={classes.inline} />
        <BooleanInput source='emailVerified' formClassName={classes.inline} />
        <ImageInput source='imgUrl' label='Image' accept='image/*'>
          <PreviewImage source='imgUrl' />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default StudentCreate;
