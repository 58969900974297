import CourseIcon from '@material-ui/icons/EventNote';

import CourseList from './CourseList';
import CourseEdit from './CourseEdit';
import CourseCreate from './CourseCreate';

export default {
  list: CourseList,
  edit: CourseEdit,
  create: CourseCreate,
  icon: CourseIcon,
};
