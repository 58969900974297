import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { cloneElement } from "react";
import {
  EditButton,
  ExportButton,
  List,
  ListButton,
  Datagrid,
  TextField,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  Filter,
  TextInput,
  CreateButton,
} from "react-admin";
import countryPhoneCodes from "../constants/countryPhoneCodes.js";

const useStyles = makeStyles({
  root: {
    marginTop: "1em",
  },
  media: {
    height: 140,
    backgroundSize: "contain",
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
});

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ListButton basePath={basePath} />
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const EmailField = ({ record, source }) => {
  record = {
    [source]: record.emailVerified == 0 ? "No" : "Yes",
  };
  return <TextField record={record} source={source} />;
};

const PhoneVerifiedField = ({ record, source }) => {
  record = {
    [source]: record.phoneVerified == 0 ? "No" : "Yes",
  };
  return <TextField record={record} source={source} />;
};

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <TextInput label="Phone" source="phone" />
    <TextInput label="Email" source="email" />
  </Filter>
);

const CountryField = ({ record, source }) => {
  record = {
    [source]: countryPhoneCodes.find((el) => el.index === record.country)?.en,
  };

  return <TextField record={record} source={source} label="Featured" />;
};

const PhoneField = ({ record, source }) => {
  record = {
    [source]: record.countryCode + record.phone,
  };

  return <TextField record={record} source={source} />;
};

export const StudentList = (props) => (
  <List {...props} actions={<ListActions />} filters={<SearchFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="email" />
      <PhoneField source="phone" />
      <CountryField source="country" />
      <EmailField source="emailVerified" />
      <PhoneVerifiedField source="phoneVerified" />
      <EditButton />
    </Datagrid>
  </List>
);

export default StudentList;
