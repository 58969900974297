import * as React from "react";
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  maxLength,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
};

const validateDescription = [maxLength(1000), required()];
const validateCompany = [maxLength(200)];

const useStyles = makeStyles(styles);

const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const redirect = (basePath, id, data) => `/testimonials`;

const TestimonialCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput
          source="name"
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source="title"
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source="company"
          resettable
          validate={validateCompany}
          formClassName={classes.inline}
        />
        <ReferenceInput
          source="courseId"
          reference="courses"
          formClassName={classes.inline}
        >
          <SelectInput
            optionText="name"
            optionValue="id"
            resettable={true}
            formClassName={classes.inline}
          />
        </ReferenceInput>
        <TextInput
          source="comment"
          multiline
          resettable
          fullWidth
          validate={validateDescription}
          formClassName={classes.margin}
        />
        <ImageInput source="imgUrl" label="Image" accept="image/*">
          <PreviewImage source="imgUrl" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default TestimonialCreate;
