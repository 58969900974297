import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import {
  BooleanInput,
  ChipField,
  DateInput,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";

export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
  tab: {
    maxWidth: "40em",
    display: "block",
  },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

const useStyles = makeStyles(styles);

const PromoTitle = ({ record }) => {
  return record ? (
    <span>
      Promo &quot;
      {record.code}&quot;
    </span>
  ) : null;
};

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
    </Toolbar>
  );
};

const PromoEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit title={<PromoTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput
          source="code"
          validate={required()}
          formClassName={classes.inline}
        />
        <SelectInput
          source="type"
          choices={[
            { id: 0, name: "Cash" },
            { id: 1, name: "Percentage" },
          ]}
          validate={required()}
          formClassName={classes.inline}
        />
        <NumberInput
          source="discount"
          validate={required()}
          formClassName={classes.inline}
        />
        <DateInput source="expiryDate" formClassName={classes.inline} />
        <NumberInput source="maxNumberOfUsers" formClassName={classes.inline} />
        <NumberInput
          source="numberOfTimesPerUser"
          formClassName={classes.inline}
        />
        <ReferenceInput
          source="clientId"
          reference="clients"
          formClassName={classes.inline}
        >
          <SelectInput
            optionText="name"
            optionValue="id"
            resettable={true}
            formClassName={classes.inline}
          />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Select Courses"
          reference="courses"
          source="courses"
          filter={{ all: true }}
          formClassName={classes.margin}
        >
          <SelectArrayInput>
            <ChipField source="name" />
          </SelectArrayInput>
        </ReferenceArrayInput>
        <BooleanInput
          source="status"
          label="Available"
          formClassName={classes.margin}
        />
        <TextField source="numberOfTimesUsed" formClassName={classes.inline} />
      </SimpleForm>
    </Edit>
  );
};

export default PromoEdit;
