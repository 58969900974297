import React from "react";
import { fetchUtils, Admin, Resource } from "react-admin";

import admins from "./admins";
import instructors from "./instructors";
import courses from "./courses";
import modules from "./modules";
import groups from "./groups";
import groupSessions from "./groupSessions";
import clients from "./clients";
import testimonials from "./testimonials";
import students from "./students";
// import reviews from './reviews';
import orders from "./orders";
import promos from "./promos";
import authProvider from "./auth/authProvider";
import myDataProvider from "./myDataProvider";

const httpClient = (url, options = {}) => {
  options.credentials = "include";
  return fetchUtils.fetchJson(url, options);
};

function App() {
  return (
    <Admin authProvider={authProvider} dataProvider={myDataProvider}>
      <Resource name="admins" {...admins} />
      <Resource name="instructors" {...instructors} />
      <Resource name="courses" {...courses} />
      <Resource name="modules" {...modules} />
      <Resource name="groups" {...groups} />
      <Resource name="groupSessions" {...groupSessions} />
      <Resource name="clients" {...clients} />
      <Resource name="testimonials" {...testimonials} />
      <Resource name="students" {...students} />
      <Resource name="orders" {...orders} />
      <Resource name="promos" {...promos} />
      {/* <Resource name='reviews' {...reviews} /> */}
    </Admin>
  );
}

export default App;
