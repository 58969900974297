import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { cloneElement } from "react";
import {
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  List,
  ListButton,
  ReferenceField,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from "react-admin";

const useStyles = makeStyles({
  root: {
    marginTop: "1em",
  },
  media: {
    height: 140,
    backgroundSize: "contain",
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
});

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ListButton basePath={basePath} />
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const TestimonialList = (props) => (
  <List {...props} actions={<ListActions />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="title" />
      <TextField source="company" />
      <ReferenceField source="courseId" reference="courses">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export default TestimonialList;
