import * as React from 'react';
import {
  Edit,
  required,
  SimpleForm,
  maxLength,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  SaveButton,
  DeleteButton,
  Toolbar,
  ReferenceField,
  TextField,
  useEditController,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const useStyles = makeStyles(styles);

const redirect = (basePath, id, data) =>
  `/groups/${data.groupId}/groupSessions`;

const CustomToolbar = (props) => {
  return (
    <Toolbar
      {...props}
      classes={useStyles()}
      style={{justifyContent: 'space-between'}}>
      <SaveButton />
      <DeleteButton
        undoable={false}
        redirect={`/groups/${props.record.groupId}/groupSessions`}
      />
    </Toolbar>
  );
};

const GroupSessionEdit = (props) => {
  const controllerProps = useEditController(props);
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = controllerProps;
  console.log(record);
  const classes = useStyles();
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect} toolbar={<CustomToolbar />}>
        <ReferenceField
          source='groupId'
          reference='groups'
          formClassName={classes.inline}>
          <TextField source='title' formClassName={classes.inline} />
        </ReferenceField>
        <ReferenceInput
          source='lectureId'
          reference='modules'
          label='Module'
          validate={required()}
          filter={{groupId: record.groupId}}
          formClassName={classes.inline}>
          <SelectInput
            optionText='title'
            optionValue='id'
            formClassName={classes.inline}
          />
        </ReferenceInput>
        <DateTimeInput
          source='date'
          formClassName={classes.inline}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export default GroupSessionEdit;
