import * as React from 'react';
import {Link} from 'react-router-dom';
import {
  Edit,
  required,
  TextInput,
  maxLength,
  ReferenceInput,
  SelectInput,
  Datagrid,
  EditButton,
  ReferenceManyField,
  TextField,
  TabbedForm,
  Pagination,
  FormTab,
  ReferenceField,
  Button,
  NumberInput,
  SaveButton,
  DeleteButton,
  Toolbar,
  SimpleForm,
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/core/styles';
import {getDate} from '../utils'


export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const DateField = ({record, source}) => {
  const newDate = getDate(record.date.toString());
  record = {
    [source]: newDate,
  };

  return <TextField record={record} source={source} />;
};

const useStyles = makeStyles(styles);

const validateTitle = maxLength(50);
const validateDescription = maxLength(1000);

const ModuleTitle = ({record}) => {
  return record ? (
    <span>
      Module &quot;
      {record.title}&quot;
    </span>
  ) : null;
};

const CreateGroupSessionButton = ({record}) => (
  <Button
    component={Link}
    to={{
      pathname: '/groupsessions/create',
      // Here we specify the initial record for the create view
      state: {record: {groupId: record.id}},
    }}
    label='Add group session'>
    <AddIcon />
  </Button>
);

const redirect = (basePath, id, data) => `/courses/${data.courseId}/modules`;

const CustomToolbar = (props) => {
  return (
    <Toolbar
      {...props}
      classes={useStyles()}
      style={{justifyContent: 'space-between'}}>
      <SaveButton />
      <DeleteButton
        undoable={false}
        redirect={`/courses/${props.record.courseId}/modules`}
      />
    </Toolbar>
  );
};

const ModuleEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title={<ModuleTitle />} {...props}>
      {/* <TabbedForm redirect={redirect} toolbar={<CustomToolbar />}> */}
      {/* <FormTab label='Details' contentClassName={classes.tab}> */}
      <SimpleForm redirect={redirect} toolbar={<CustomToolbar />}>
        <TextInput
          source='title'
          validate={validateTitle}
          formClassName={classes.inline}
        />
        <ReferenceInput
          source='courseId'
          reference='courses'
          validate={required()}
          formClassName={classes.inline}>
          <SelectInput
            optionText='name'
            optionValue='id'
            formClassName={classes.inline}
          />
        </ReferenceInput>
        <NumberInput
          source='duration'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='description'
          multiline
          resettable
          fullWidth
          validate={validateDescription}
          formClassName={classes.margin}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ModuleEdit;
