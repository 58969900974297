import * as React from 'react';
import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  maxLength,
  email,
  BooleanInput,
  ImageInput,
  ImageField,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const useStyles = makeStyles(styles);

const validateEmail = [required(), email()];
const validateTitle = maxLength(100);
const validateDescription = maxLength(500);
const validateSocial = maxLength(100);

const PreviewImage = ({record, source}) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const InstructorEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source='name'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='email'
          validate={validateEmail}
          formClassName={classes.inline}
        />
        <TextInput
          source='countryCode'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='phone'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='title'
          validate={validateTitle}
          formClassName={classes.inline}
        />
        <TextInput
          source='facebook'
          validate={validateSocial}
          formClassName={classes.inline}
        />
        <TextInput
          source='twitter'
          validate={validateSocial}
          formClassName={classes.inline}
        />
        <TextInput
          source='linkedin'
          validate={validateSocial}
          formClassName={classes.inline}
        />
        <BooleanInput
          source='isTeam'
          label='Team Member'
          formClassName={classes.inline}
        />
        <TextInput
          source='description'
          multiline
          resettable
          fullWidth
          validate={validateDescription}
          formClassName={classes.margin}
        />
        <ImageInput source='imgUrl' label='Image' accept='image/*'>
          <PreviewImage source='imgUrl' />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export default InstructorEdit;
