import * as React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const useStyles = makeStyles(styles);

const PreviewImage = ({record, source}) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const ClientCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source='name'
          validate={required()}
        />
        <ImageInput source='imgUrl' label='Image' accept='image/*'>
          <PreviewImage source='imgUrl' />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default ClientCreate;
