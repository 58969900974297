import * as React from 'react';
import {Link} from 'react-router-dom';
import {
  Edit,
  required,
  TextInput,
  maxLength,
  ReferenceInput,
  SelectInput,
  Datagrid,
  EditButton,
  ReferenceManyField,
  TextField,
  TabbedForm,
  Pagination,
  FormTab,
  ReferenceField,
  Button,
  SaveButton,
  DeleteButton,
  Toolbar,
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/core/styles';
import {getDate} from '../utils';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const useStyles = makeStyles(styles);

const validateTitle = maxLength(50);

const validateTextArea = maxLength(2000);

const DateField = ({record, source}) => {
  if (record) {
    const newDate = getDate(record.date.toString());
    record = {
      [source]: newDate,
    };
  }

  return <TextField record={record} source={source} />;
};

const GroupTitle = ({record}) => {
  return record ? (
    <span>
      Group &quot;
      {record.title}&quot;
    </span>
  ) : null;
};

const CreateGroupSessionButton = ({record}) => (
  <Button
    component={Link}
    to={{
      pathname: '/groupsessions/create',
      // Here we specify the initial record for the create view
      state: {record: {groupId: record.id}},
    }}
    label='Add group session'>
    <AddIcon />
  </Button>
);

const redirect = (basePath, id, data) => `/courses/${data.courseId}/groups`;

const CustomToolbar = (props) => {
  return (
    <Toolbar
      {...props}
      classes={useStyles()}
      style={{justifyContent: 'space-between'}}>
      <SaveButton />
      <DeleteButton
        undoable={false}
        redirect={`/courses/${props.record.courseId}/groups`}
      />
    </Toolbar>
  );
};

const GroupEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title={<GroupTitle />} {...props}>
      <TabbedForm redirect={redirect} toolbar={<CustomToolbar />}>
        <FormTab label='Details' contentClassName={classes.tab}>
          <TextInput
            source='title'
            validate={validateTitle}
            formClassName={classes.inline}
          />
          <ReferenceInput
            source='courseId'
            reference='courses'
            validate={required()}
            formClassName={classes.inline}>
            <SelectInput
              optionText='name'
              optionValue='id'
              formClassName={classes.inline}
            />
          </ReferenceInput>
          <TextInput
            source='zoomLink'
            label='Zoom Link (Must start with https://)'
            multiline
            resettable
            fullWidth
            validate={validateTextArea}
            formClassName={classes.margin}
          />
        </FormTab>

        <FormTab label='Group Sessions' path='groupSessions'>
          <ReferenceManyField
            reference='groupSessions'
            target='groupId'
            addLabel={false}
            pagination={<Pagination />}
            fullWidth>
            <Datagrid>
              <TextField source='id' />
              <ReferenceField source='lectureId' reference='modules'>
                <TextField source='title' />
              </ReferenceField>
              <ReferenceField source='groupId' reference='groups'>
                <TextField source='title' />
              </ReferenceField>
              <DateField source='date' />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <CreateGroupSessionButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default GroupEdit;
