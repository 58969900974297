import {makeStyles} from '@material-ui/core/styles';
import * as React from 'react';
import {cloneElement} from 'react';
import {
  CreateButton,
  EditButton,
  ExportButton,
  List,
  ListButton,
  Datagrid,
  TextField,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
} from 'react-admin';

// import LinkToRelatedProducts from './LinkToRelatedProducts';
// import {Category} from '../types';

const useStyles = makeStyles({
  root: {
    marginTop: '1em',
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
  },
  title: {
    paddingBottom: '0.5em',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const ListActions = (props) => {
  const {className, exporter, filters, maxResults, ...rest} = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ListButton basePath={basePath} />
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const TeamField = ({record, source}) => {
  record = {
    [source]: record?.isTeam == 0 ? 'No' : 'Yes',
  };
  return <TextField record={record} source={source} label='Team Member' />;
};

const PhoneField = ({record, source}) => {
  if (record) {
    record = {
      [source]: record.countryCode + record.phone,
    };
  }

  return <TextField record={record} source={source} />;
};

export const InstructorList = (props) => (
  <List {...props} actions={<ListActions />}>
    <Datagrid>
      <TextField source='name' />
      <TextField source='email' />
      <PhoneField source='phone' />
      <TeamField source='isTeam' label='Team Member' />
      <TextField source='title' />
      <TextField source='facebook' />
      <TextField source='twitter' />
      <TextField source='linkedin' />
      <EditButton />
    </Datagrid>
  </List>
);

export default InstructorList;
