import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import {
  BooleanInput,
  ChipField,
  Create,
  DateInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
};

const useStyles = makeStyles(styles);

const redirect = (basePath, id, data) => `/promos`;

const PromoCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput
          source="code"
          validate={required()}
          formClassName={classes.inline}
        />
        <SelectInput
          source="type"
          choices={[
            { id: 0, name: "Cash" },
            { id: 1, name: "Percentage" },
          ]}
          validate={required()}
          formClassName={classes.inline}
        />
        <NumberInput
          source="discount"
          validate={required()}
          formClassName={classes.inline}
        />
        <DateInput source="expiryDate" formClassName={classes.inline} />
        <NumberInput source="maxNumberOfUsers" formClassName={classes.inline} />
        <NumberInput
          source="numberOfTimesPerUser"
          formClassName={classes.inline}
        />
        <ReferenceInput
          source="clientId"
          reference="clients"
          formClassName={classes.inline}
        >
          <SelectInput
            optionText="name"
            optionValue="id"
            resettable={true}
            formClassName={classes.inline}
          />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Select Courses"
          reference="courses"
          source="courses"
          filter={{ all: true }}
          defaultValue={[-1]}
          formClassName={classes.margin}
        >
          <SelectArrayInput>
            <ChipField source="name" />
          </SelectArrayInput>
        </ReferenceArrayInput>
        <BooleanInput
          source="status"
          label="Available"
          formClassName={classes.margin}
        />
      </SimpleForm>
    </Create>
  );
};

export default PromoCreate;
