import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { cloneElement } from "react";
import {
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  List,
  ListButton,
  ReferenceField,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from "react-admin";

const useStyles = makeStyles({
  root: {
    marginTop: "1em",
  },
  media: {
    height: 140,
    backgroundSize: "contain",
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
});

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ListButton basePath={basePath} />
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const TypeField = ({ record, source }) => {
  record = {
    [source]: record.type === 0 ? "Cash" : "Percentage",
  };
  return <TextField record={record} source={source} />;
};

const DiscountField = ({ record, source }) => {
  record = {
    [source]: `${record.discount.toFixed(2)} ${
      record.type === 0 ? " USD" : " %"
    }`,
  };
  return <TextField record={record} source={source} />;
};

const StatusField = ({ record, source }) => {
  record = {
    [source]: record.status === 0 ? "Inactive" : "Active",
  };
  return <TextField record={record} source={source} />;
};

const DateField = ({ record, source }) => {
  const newDate = new Date(record.expiryDate.toString())
    .toLocaleString("en-GB")
    .split(",")[0];

  record = {
    [source]: newDate,
  };

  return <TextField record={record} source={source} />;
};

export const PromoList = (props) => (
  <List {...props} actions={<ListActions />}>
    <Datagrid>
      <TextField source="code" />
      <TypeField source="type" />
      <DiscountField source="discount" />
      <StatusField source="status" />
      <DateField source="expiryDate" />
      <ReferenceField source="clientId" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="numberOfTimesUsed" />
      <EditButton />
    </Datagrid>
  </List>
);

export default PromoList;
