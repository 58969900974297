import * as React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  maxLength,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const validateTextArea = maxLength(2000);

const useStyles = makeStyles(styles);

const validateTitle = maxLength(50);

const redirect = (basePath, id, data) => `/courses/${data.courseId}/groups`;

const GroupCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput
          source='title'
          validate={validateTitle}
          formClassName={classes.inline}
        />
        <ReferenceInput
          source='courseId'
          reference='courses'
          validate={required()}
          formClassName={classes.inline}>
          <SelectInput
            optionText='name'
            optionValue='id'
            formClassName={classes.inline}
          />
        </ReferenceInput>
        <TextInput
          source='zoomLink'
          label='Zoom Link (Must start with https://)'
          multiline
          resettable
          fullWidth
          validate={validateTextArea}
          formClassName={classes.margin}
        />
      </SimpleForm>
    </Create>
  );
};

export default GroupCreate;
