import ClientIcon from '@material-ui/icons/Group';

import ClientList from './ClientList';
import ClientEdit from './ClientEdit';
import ClientCreate from './ClientCreate';

export default {
  list: ClientList,
  edit: ClientEdit,
  create: ClientCreate,
  icon: ClientIcon,
};
