import PromoIcon from '@material-ui/icons/LocalOffer';

import PromoList from './PromoList';
import PromoEdit from './PromoEdit';
import PromoCreate from './PromoCreate';

export default {
  list: PromoList,
  edit: PromoEdit,
  create: PromoCreate,
  icon: PromoIcon,
};
