import * as React from "react";
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  maxLength,
  ImageInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ImageField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
};

const useStyles = makeStyles(styles);

const validateTextArea = maxLength(1000);

const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const redirect = (basePath, id, data) => `/courses`;

const CourseCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput
          source="name"
          validate={required()}
          formClassName={classes.inline}
        />
        <NumberInput
          source="price"
          validate={required()}
          formClassName={classes.inline}
        />
        <ReferenceInput
          source="instructorId"
          reference="instructors"
          validate={required()}
          formClassName={classes.inline}
        >
          <SelectInput
            optionText="name"
            optionValue="id"
            formClassName={classes.inline}
          />
        </ReferenceInput>
        <TextInput
          source="duration"
          resettable
          formClassName={classes.inline}
        />
        <TextInput
          source="description"
          multiline
          resettable
          fullWidth
          validate={validateTextArea}
          formClassName={classes.margin}
        />
        <TextInput
          source="whatYouWillLearn"
          multiline
          resettable
          fullWidth
          validate={validateTextArea}
          formClassName={classes.margin}
        />
        <TextInput
          source="whoThisCourseIsFor"
          multiline
          resettable
          fullWidth
          validate={validateTextArea}
          formClassName={classes.margin}
        />
        <ImageInput source="imgUrl" label="Image" accept="image/*">
          <PreviewImage source="imgUrl" />
        </ImageInput>
        <BooleanInput
          source="isFeatured"
          label="Featured"
          formClassName={classes.inline}
        />
        <BooleanInput
          source="status"
          label="Available"
          formClassName={classes.inline}
        />
      </SimpleForm>
    </Create>
  );
};

export default CourseCreate;
