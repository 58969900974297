import StudentIcon from '@material-ui/icons/Person';

import StudentList from './StudentList';
import StudentEdit from './StudentEdit';
import StudentCreate from './StudentCreate';

export default {
  list: StudentList,
  edit: StudentEdit,
  create: StudentCreate,
  icon: StudentIcon,
};
