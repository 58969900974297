import TestimonialIcon from '@material-ui/icons/Chat';

import TestimonialList from './TestimonialList';
import TestimonialEdit from './TestimonialEdit';
import TestimonialCreate from './TestimonialCreate';

export default {
  list: TestimonialList,
  edit: TestimonialEdit,
  create: TestimonialCreate,
  icon: TestimonialIcon,
};
