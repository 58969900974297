import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import {
  BooleanInput,
  Edit,
  maxLength,
  NumberInput,
  ReferenceField,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
} from "react-admin";

export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
};

const useStyles = makeStyles(styles);

const validateTextArea = maxLength(1000);

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
    </Toolbar>
  );
};

const PromoDiscountField = ({ record, source, ...rest }) => {
  record = {
    [source]: record.promoDiscount
      ? `${record.promoDiscount.toFixed(2)} USD`
      : null,
  };
  return <TextField record={record} source={source} {...rest} />;
};

PromoDiscountField.defaultProps = {
  addLabel: true,
};

const OrderEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <ReferenceField
          source="studentId"
          reference="students"
          formClassName={classes.inline}
        >
          <TextField source="name" formClassName={classes.inline} />
        </ReferenceField>
        <ReferenceField
          source="courseId"
          reference="courses"
          formClassName={classes.inline}
        >
          <TextField source="name" formClassName={classes.inline} />
        </ReferenceField>
        <ReferenceField
          source="groupId"
          reference="groups"
          formClassName={classes.inline}
        >
          <TextField source="title" formClassName={classes.inline} />
        </ReferenceField>
        <NumberInput source="price" formClassName={classes.inline} />
        <ReferenceField
          source="promoId"
          reference="promos"
          formClassName={classes.inline}
        >
          <TextField source="code" />
        </ReferenceField>
        <PromoDiscountField
          source="promoDiscount"
          formClassName={classes.inline}
        />
        <BooleanInput
          source="status"
          label="Confirmed"
          formClassName={classes.inline}
        />
      </SimpleForm>
    </Edit>
  );
};

export default OrderEdit;
