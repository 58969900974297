import InstructorIcon from '@material-ui/icons/Work';

import InstructorList from './InstructorList';
import InstructorEdit from './InstructorEdit';
import InstructorCreate from './InstructorCreate';

export default {
  list: InstructorList,
  edit: InstructorEdit,
  create: InstructorCreate,
  icon: InstructorIcon,
};
