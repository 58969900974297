import * as React from 'react';
import {
  Create,
  required,
  SimpleForm,
  maxLength,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  TextInput,
  ReferenceField,
  TextField,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const useStyles = makeStyles(styles);

const redirect = (basePath, id, data) =>
  `/groups/${data.groupId}/groupSessions`;

const GroupSessionCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceField
          source='groupId'
          reference='groups'
          formClassName={classes.inline}>
          <TextField source='title' formClassName={classes.inline} />
        </ReferenceField>
        <ReferenceInput
          source='lectureId'
          reference='modules'
          validate={required()}
          label='Module'
          filter={{groupId: props.location.state.record.groupId}}
          formClassName={classes.inline}>
          <SelectInput
            optionText='title'
            optionValue='id'
            formClassName={classes.inline}
          />
        </ReferenceInput>
        <DateTimeInput
          source='date'
          formClassName={classes.inline}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default GroupSessionCreate;
