import * as React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  PasswordInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  password: {display: 'inline-block'},
  confirmPassword: {display: 'inline-block', marginLeft: 32},
};

const useStyles = makeStyles(styles);

export const validatePasswords = ({password, confirmPassword}) => {
  const errors = {};
  if (password && confirmPassword && password !== confirmPassword) {
    errors.confirmPassword = [
      'The password confirmation is not the same as the password.',
    ];
  }
  return errors;
};

const AdminCreate = (props) => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm validate={validatePasswords}>
        <TextInput source="username" validate={required()} />
        <TextInput source="name" validate={required()} />
        <PasswordInput
          source="password"
          validate={required()}
          formClassName={classes.password}
        />
        <PasswordInput
          source="confirmPassword"
          validate={required()}
          formClassName={classes.confirmPassword}
        />
      </SimpleForm>
    </Create>
  );
};

export default AdminCreate;
